.PropertyEditMoney__InputWrapper {
    display: flex;
    width: 200px;

    .MyTextInput__LeftIcon {
        width: 16px;
        height: 16px;
    }

    .FieldValidator__ErrorWrapper.hide {
        display: none;
    }
}
