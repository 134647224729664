@import '../../../../variables.scss';

.SalesOrderEditModal__Frame {
    width: 600px;
}

// .SalesOrderEditModal__PropertyContainer {
//     .MuiFormControl-root {
//         width: 100%;
//     }
// }
