@import '../../../../variables.scss';

.SalesOrderDetailModal__Frame {
    width: 1000px;
}

.SalesOrderDetailModal__PageHeader__Actions {
    display: flex;
    gap: 6px;
}

.SalesOrderDetailModal__CancelledMessage {
    margin-bottom: 24px;
}

.SalesOrderDetailModal__Details {
    display: flex;
    flex-direction: row-reverse;
    flex: 1 1;
    justify-content: space-between;
}

.SalesOrderDetailModal__Sidebar {
    max-width: 38.2%; // golden ratio ϕ
    padding-left: 24px;
    min-width: 180px;
}

.SalesOrderDetailModal__Sidebar__GroupOrdersCount {
    font-size: 13px;
    color: $color-grey-base;
}

.SalesOrderDetailModal__Tabs {
    margin-top: 12px;
}

.SalesOrderDetailModal__ItemTrafficLights {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
}

.SalesOrderDetailModal__ItemTrafficLights__Tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.SalesOrderDetailModal__NotesHtml {
    & *:first-child {
        margin-top: 0;
    }
    & *:last-child {
        margin-bottom: 0;
    }
}

.SalesOrderDetailModal__StatusBadges {
    display: flex;
    gap: 6px;
    align-items: center;
    flex-wrap: wrap;
}

.SalesOrderDetailModal__ArchivedBadge {
    font-size: 14px;
    background-color: $color-warning-base;
    color: $color-warning-darker;
    border-radius: 12px;
    padding: 2px 12px;
    font-weight: $font-weight-semi-bold;
}

.SalesOrderDetailModal__ItemsToolbar {
    overflow: hidden;
    max-height: 40px;
    margin-bottom: 8px;
    transition: max-height 0.3s ease, margin-bottom 0.3s ease;
    display: flex;
    gap: 6px;
    align-items: center;

    &.collapsed {
        max-height: 0;
        margin-bottom: 0;
        opacity: 0;
    }
}
